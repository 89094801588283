<template>
  <div>
    <div v-if="datalist.title!=null">
      <van-row class="mt15">
        <van-col span="16" offset="1" class="font15">{{ datalist.title }}</van-col>
        <van-col span="7" class="mright">
          <div class="" >
            <open-app :ids="ids"></open-app>
          </div>
        </van-col>
      </van-row>
      <div class="line"></div>
      <div v-for="(item,key) in datalist.groups" :key="key" class="mt2vh">
        <van-row class="bgf2 padd8">
          <van-col span="23" offset="1" class="font14">{{ item.title }}
            <span v-if="item.type == 'list'" class="ml15" @click="changeshow">
              <van-icon name="arrow-down" color="#e5e5e5" size="14"/>
            </span>
          </van-col>
        </van-row>
        <van-row v-if="item.type == 'data'" class="mt5">
          <van-col :span="22" offset="1" class="mb11 ml6v">
            <div v-for="(items,keys) in item.fields" :key="keys">
              <div v-if="items.visible == true">
                <van-row>
                  <van-col :span="24" class="padd3 font13"><span style="color: #7f7f7f;">{{ items.title }}</span></van-col>
                </van-row>
                <van-row>
                  <van-col :span="24" class="mt10 mb5 font14">
                    <div v-if="items.type=='select'">
                      <p v-if="!isEmptyData(datainfo[items.name])">
                        {{datainfo[items.name].label.nickName!=undefined?datainfo[items.name].label.nickName+' '+datainfo[items.name].label.phoneNum:datainfo[items.name].label}}
                      </p>
                      <p v-else></p>
                    </div>
                    <div v-else-if="items.type=='mutiselect'">
                      <p v-if="!isEmptyData(datainfo[items.name])">
                        <span v-for="(i,ke) in datainfo[items.name]" :key="ke">{{ i.label }}&nbsp;</span>
                      </p>
                      <p v-else></p>
                    </div>
                    <div v-else-if="items.type=='dept'">
                      <p v-if="!isEmptyData(datainfo[items.name])">{{datainfo[items.name].label}}</p>
                      <p v-else></p>
                    </div>
                    <div v-else-if="items.type=='contacts'">
                      <p v-if="!isEmptyData(datainfo[items.name])&&datainfo[items.name].phoneNum!=undefined">
                        {{datainfo[items.name].nickName +' '+ datainfo[items.name].phoneNum}}
                      </p>
                      <p v-else-if="!isEmptyData(datainfo[items.name])&&datainfo[items.name].phoneNum==undefined">
                        {{datainfo[items.name].nickName}}
                      </p>
                      <p v-else></p>
                    </div>
                    <div v-else-if="items.type == 'sign'">
                      <van-image v-if="!isEmptyData(datainfo[items.name])" width="150" height="60" :src="datainfo[items.name]"></van-image>
                    </div>
                    <div v-else-if="items.type == 'img'||items.type == 'file'">
                      <div v-if="!isEmptyData(datainfo[items.name])">
                        <van-image width="3rem" height="4rem" v-for="(it,k) in datainfo[items.name].list" :key="k"
                                   :src="it" class="mr5" @click="showimg(datainfo[items.name].list)">
                          <template v-slot:loading>
                            <van-loading type="spinner" size="16"/>
                          </template>
                        </van-image>
                      </div>
                      <div v-else></div>
                    </div>
                    <div v-else>
                      <p v-if="!isEmptyData(datainfo[items.name])">{{datainfo[items.name]}}</p>
                      <p v-else></p>
                    </div>

                  </van-col>
                </van-row>
                <div class="line1"></div>
              </div>
            </div>
          </van-col>
        </van-row>

        <van-row v-if="item.type == 'list' && show == true" class="mb11 mt5">
          <van-col span="22" offset="1" class="" v-for="(it,ke) in datainfo[item.name]" :key="ke">
            <div v-for="(items,keys) in item.fields" :key="keys">
              <div v-if="items.visible == true" class="ml10">
                <p class="mtb7">
                  <span style="color: #7f7f7f;" class="mb7 font13">{{ items.title }}：</span>
                  <span class="font14 col32" v-if="items.type == 'mutiselect'">
                <span v-for="(i,k) in it[itms.name]" :key="k">{{ i.label }}</span>
              </span>
                  <span class="font14 col32" v-else-if="items.type == 'select'" >
                  {{ it[items.name].label }}
                    <!--                  {{ it[items.name].label.nickName !=undefined? it[items.name].label.nickName: it[items.name].label}}-->
                </span>
                  <span v-else-if="items.type == 'contacts'">{{ datainfo[items.name].nickName}} +' '+ <span v-if="datainfo[items.name].phoneNum!=undefined">{{ datainfo[items.name].phoneNum }}</span></span>
                  <span class="font14 col32" v-else-if="items.type == 'img'">
                <van-image width="3rem" height="4rem" v-if="it[items.name].list&&it[items.name].list.length > 0" v-for="(itt,k) in it[items.name].list" :key="k" :src="itt"
                           class="mr5" @click="showimg(it[items.name].list)">
                    <template v-slot:loading>
                      <van-loading type="spinner" size="16"/>
                    </template>
                  </van-image>
              </span>
                  <span class="font14 col32" v-else>{{ it[items.name] }}</span>
                </p>
              </div>
            </div>
            <div class="mtb3" v-if="ke !== (datainfo[item.name].length - 1)"><div class="padd3 bgf2"></div></div>
          </van-col>
        </van-row>
<!--        <div class="lines"></div>-->
      </div>
    </div>
  </div>
</template>

<script>
import {ImagePreview} from "vant";
import mixins from "@/mixins/index";
import openApp from "@/components/openApp";
//import wx from "weixin-js-sdk";
//import sha1 from "js-sha1"

//import axios from "axios";
//let wx = require('weixin-js-sdk')
export default {
  name: "infoDisplay",
  props: ['datalist','datainfo','ids'],
  mixins: [mixins],
  components: {
    openApp,
  },
  data(){
    return{
      show: true,
      ticket: "",
    }
  },
  created() {
    //this.gettic();
  },
  mounted() {

    //console.log("console")
  },
  methods: {
     /*async gettic(){
      let accesstoken = '56_1YzppTLR1A3P_Gjoe9JIq0o0naz37LVC_FBI97SuC9tg2gfLJADnfa0bin7Lq4-yfDGnieV9nufesPLTn0QZGyuf5961dNNz3G6qrxwXIQVNJvpJcU7J04M9aMK_c3s0OGx-gyTkJyNUEL4DPFReAIAEUG';
      let url = '/cgi-bin/ticket/getticket?access_token='+accesstoken+'&type=jsapi'
      this.ticket = await axios({
        method: 'get',
        url: '/tapi' + url,
      }).then(res=>{
        console.log("res",res)
        if (res != null && res.data != null){
          if (!this.isEmptyData(res.data.ticket)){
            return  res.data.ticket
          }
        }
      })
       if (!this.isEmptyData(this.ticket)){
         this.init()
       }
    },
    init(){
      // this.ticket = await this.gettic();
      //https://api.weixin.qq.com/cgi-bin/ticket/getticket?access_token=ACCESS_TOKEN&type=jsapi
      const appid = 'wx5ef0e4fab3c1525d';
      const noncestr = 'Wm3WZYTPz0wzccnW';
      //let date = new Date();
      //let time = date.getTime();
      let time = 1650858889;
      let url = location.href.split('#')[0];
      console.log("ticket",this.ticket)//
      let sig = "jsapi_ticket="+this.ticket+"&noncestr="+noncestr+"&timestamp="+time+"&url="+url;
      sig = sha1(sig);
      console.log("sha1",sig)
      wx.config({
        debug: true,
        appId: appid,//wx5ef0e4fab3c1525d
        timestamp: time,
        nonceStr: noncestr,
        signature: sig,
        jsApiList: ['updateTimelineShareData'],
        openTagList: ['wx-open-launch-app'],
      })
      wx.ready(function (res){
        console.log("res",res)
        /!*wx.checkJsApi({
          jsApiList: ['wx-open-launch-app'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
          success: function (res) {
            console.log('可用',res)
          },
          fail: (err) => {
            console.log(err, '不可用')
          }
        })*!/
      })
      wx.error(function (err){
        console.log("err",err)
      })
    },
    handleErrorFn(){},
    handleLaunchFn(e){
      console.log("eee",e)
      this.$data.wechatOpenAppData ="【这里是launch 函数】"+ JSON.stringify(e)
    },*/
    toApp(){
      //let url = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.fourbull.pm';
      let url = "sixu://sixu.work?dataId="+this.ids.dataId+"&appId="+this.ids.appId+"&companyId="+this.ids.companyId+"&FiledStatus="+this.ids.FiledStatus+"&upDataId="+this.ids.upDataId;
      window.location.href = url;
    },
    changeshow() {
      this.show = !this.show;
    },
    showimg(img) {//图片预览
      ImagePreview({
        images: img,
        closeable: true,
      });
    },
  }
}
</script>

<style scoped>
.col32 {color: #323233;}
.mt4vh {margin-top: 4vh;}
.mt2vh {margin-top: 2vh;}  .ml5 {margin-left: 5px;}
.ml6vh {margin-left: 6vw;}
.mb11 {margin-bottom: 11px;}
.mb10 {margin-bottom: 2vh;}
.lines {border-top: #f2f2f2 solid 1px;width: 94vw;margin: 20px auto;}
.mr5 {margin-right: 5px;}
.wxopen{}
</style>