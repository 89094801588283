<template>
  <div>
<!--    <div v-if="datalist.title!=null">
      <van-row>
        <van-col span="16" offset="1" class="mt4">{{ datalist.title }}</van-col>
      </van-row>
      <div class="line"></div>
      <div v-for="(item,key) in datalist.groups" :key="key" class="mt20">
        <van-row>
          <van-col span="23" offset="1" class="mb10">{{ item.title }}
            <span v-if="item.type == 'list'" class="ml15" @click="changeshow"><van-icon name="arrow-down" color="#e5e5e5" size="14"/></span>
          </van-col>
        </van-row>
        <van-row v-if="item.type == 'data'">
          <van-col :span="22" offset="1" class="mb11 ml6v">
            <div v-for="(items,keys) in item.fields" :key="keys">
              <van-row>
                <van-col :span="24"><span style="color: #7f7f7f;">{{ items.title }}</span></van-col>
              </van-row>
              <van-row>
                <van-col :span="24">
                  <van-cell-group>
                    <div v-if="items.type=='select'">
                      <van-cell v-if="datainfo[items.name]!=null&&datainfo[items.name]!=''"
                                :value="datainfo[items.name].label.nickName!=undefined?datainfo[items.name].label.nickName+' '+datainfo[items.name].label.phoneNum:datainfo[items.name].label"/>
                      <van-cell v-else/>
                    </div>
                    <div v-else-if="items.type=='mutiselect'">
                      <van-cell v-if="datainfo[items.name]!=null&&datainfo[items.name]!=''">
                        <template #default>
                          <span v-for="(i,ke) in datainfo[items.name]" :key="ke">{{ i.label }}&nbsp;</span>
                        </template>
                      </van-cell>
                      <van-cell v-else/>
                    </div>
                    <div v-else-if="items.type=='contacts'">
                      <van-cell v-if="datainfo[items.name]!=null&&datainfo[items.name]!=''"
                                :value="datainfo[items.name].nickName +' '+ datainfo[items.name].phoneNum">
                      </van-cell>
                      <van-cell v-else/>
                    </div>
                    <div v-else-if="items.type == 'img'||items.type == 'file'">
                      <van-cell v-if="datainfo[items.name]!=null&&datainfo[items.name]!=''">
                        <template #extra>
                          <van-image width="3rem" height="4rem" v-for="(it,k) in datainfo[items.name].list" :key="k"
                                     :src="it" class="mr5" @click="showimg(datainfo[items.name].list)">
                            <template v-slot:loading>
                              <van-loading type="spinner" size="16"/>
                            </template>
                          </van-image>
                        </template>
                      </van-cell>
                      <van-cell v-else>
                        &lt;!&ndash;                     <template #extra>
                                               <van-image width="3rem" height="3rem" v-for="(it,k) in img" :key="k" :src="it" @click="showimg(img)">
                                                 <template v-slot:loading>
                                                   <van-loading type="spinner" size="16" />
                                                 </template>
                                               </van-image>
                                             </template>&ndash;&gt;
                      </van-cell>
                    </div>
                    <div v-else>
                      <van-cell v-if="datainfo[items.name]!=null&&datainfo[items.name]!=''"
                                :value="datainfo[items.name]"></van-cell>
                      <van-cell v-else/>
                    </div>
                  </van-cell-group>
                </van-col>
              </van-row>
            </div>
          </van-col>
        </van-row>
        <van-row v-if="item.type == 'list' && show == true" class="mb11 mt5">
          <van-col span="22" offset="1" class=" ml6v" v-for="(it,ke) in datainfo[item.name]" :key="ke">
            <div v-for="(items,keys) in item.fields" :key="keys">
              <p class="mb5 mt5">
                <span style="color: #7f7f7f;" class="mb7">{{ items.title }}：</span>
                <span class="font14 col32" v-if="items.type == 'mutiselect'">
                <span v-for="(i,k) in it[itms.name]" :key="k">{{ i.label }}</span>
              </span>
                <span class="font14 col32" v-else-if="items.type == 'select'" >
                  {{ it[items.name].label }}
&lt;!&ndash;                  {{ it[items.name].label.nickName !=undefined? it[items.name].label.nickName: it[items.name].label}}&ndash;&gt;
                </span>
                <span v-else-if="items.type == 'contacts'">{{ datainfo[items.name].nickName +' '+ datainfo[items.name].phoneNum }}</span>
                <span class="font14 col32" v-else-if="items.type == 'img'">
                <van-image width="3rem" height="4rem" v-for="(itt,k) in it[items.name].list" :key="k" :src="itt"
                           class="mr5" @click="showimg(it[items.name].list)">
                    <template v-slot:loading>
                      <van-loading type="spinner" size="16"/>
                    </template>
                  </van-image>
              </span>
                <span class="font14 col32" v-else>{{ it[items.name] }}</span>
              </p>
            </div>
            <div class="line1"></div>
          </van-col>
        </van-row>
        <div class="line"></div>
      </div>
    </div>-->
    <div v-if="dis == 3" class="mcenter mt45vh">
      <div class="border p10">
        <open-app :ids="ids"></open-app>
      </div>
    </div>
    <div v-else>
      <info-display :datalist="datalist" :datainfo="datainfo" :ids="ids"></info-display>
    </div>
  </div>
</template>

<script>
import qs from 'qs';
import {getShareData} from "@/api/api";
import { Toast} from "vant";
import infoDisplay from "@/components/infoDisplay";
import mixins from '@/mixins';
import openApp from "@/components/openApp";

export default {
  name: "share",
  mixins: [mixins],
  data() {
    return {
      imgUrl: "http://bullpm-1253367923.cos.ap-chengdu.myqcloud.com",
      imgCompress: "?imageMogr2/thumbnail/!70p",
      id: "",
      sign: "",
      datalist: {},
      datainfo: {},
      datadef: {
        visible: null,//可见权限
        inherit: null,
      },
      companyId: "",
      visible: true,
      ids: {
        dataId: "",
        appId: "",
        companyId: "",
        upDataId: "",
        FiledStatus: "",
      },
      dis: ""
    }
  },
  mounted() {
    this.initData();
    this.getCode();
  },
  components: {
    infoDisplay,
    openApp,
  },
  methods: {
    initData(){
      this.datainfo = {};
      this.datalist = {};
      this.datadef = {
        visible: null,
        inherit: null
      }
    },
    getCode() {
      /*let href = "https://wx.sixu.work/pubshare?id=c38e432372d54a219d7a1f04902973e6&sign=51407ab8ffdc2b9d5788bbdb82f4b600"
      if (href.indexOf("id") != -1) {
        this.id = qs.parse(href.split("#")[0].split("?")[1]).id;
        this.sign = qs.parse(href.split("#")[0].split("?")[1]).sign;
      }*/
      if (window.location.href.indexOf("id") != -1){
        this.id = qs.parse(window.location.href.split("#")[0].split("?")[1]).id;
        this.sign = qs.parse(window.location.href.split("#")[0].split("?")[1]).sign
      }
      if (this.id) {
        getShareData(this.id).then(res => {
          //console.log("share",res);
          if (res != null && res.data != null && res.data.content != null) {
            let contents = res.data.content;
            if (!this.isEmptyData(contents.data.dataId)){
              this.ids = {
                dataId: contents.data.dataId,
                appId: contents.data.appId,
                FiledStatus: contents.data.FiledStatus,
                upDataId: contents.data.upDataId,
              }
            }
            if (!this.isEmptyData(contents.form.companyId)){
              this.ids.companyId = contents.form.companyId;
            }
            //console.log("ids",this.ids)
            if (!this.isEmptyData(contents.form.formDef)){
              this.datalist = JSON.parse(contents.form.formDef)
              //console.log('contents', JSON.parse(contents.form.formDef));
            }
            if (!this.isEmptyData(contents.form.dataDef)){
              let def = JSON.parse(contents.form.dataDef)
              if (!this.isEmptyData(def.viewData.visible)){
                this.visible = true;
                this.datadef.visible = def.viewData.visible;
              }else {
                this.visible = false;
              }
              //console.log("datadef",this.datadef);
            }else {
              this.visible = true;
            }
            //console.log('form', this.datalist);
            if (!this.isEmptyData(contents.data)) {
              this.datainfo = contents.data;
              //console.log('datainfo', this.datainfo);
            }
            let list = [];//模板
            for (let i in this.datalist.groups) {
              let item = this.datalist.groups[i];
              if (item.type == "template"){
                list.push({name: item.name, data: item.fields});
              }
              for (let j in item.fields) {
                let items = item.fields[j];
                items.visible = true;
                if (items.type == 'img') {//当有img时，会组装img路径如下所示
                  if (this.datainfo[items.name] != null && this.datainfo[items.name] != '') {
                    this.datainfo[items.name].list = [];
                    for (let k in this.datainfo[items.name]) {
                      let d = this.datainfo[items.name][k];
                      if (d.fileName != null && d.fileName != '') {
                        this.datainfo[items.name].list.push(this.imgUrl + "/" + this.ids.companyId + "/" + d.fileName + this.imgCompress);
                      }
                    }
                  }
                }
                if (items.type == 'file') {
                  if (this.datainfo[items.name] != null && this.datainfo[items.name] != '') {
                    this.datainfo[items.name].list = [];
                    for (let k in this.datainfo[items.name]) {
                      let d = this.datainfo[items.name][k];
                      if (d.fileName != null && d.fileName != '') {
                        this.datainfo[items.name].list.push(this.imgUrl + "/" + this.ids.companyId + "/" + d.fileName);
                      }
                    }
                  }
                }
                if (items.type == 'sign') {
                  this.datainfo[items.name] = "data:image/png;base64," + this.datainfo[items.name]
                }
                //是否可见
                if (this.visible == true){
                  //console.log("hhh",this.datadef)
                  if (this.datadef.visible != null){
                    if (!this.isEmptyData(this.datadef.visible[items.name]) && this.datadef.visible[items.name].indexOf('visible') === -1){
                      //console.log("items.name",items.name,items.title)
                      items.visible = false;
                    }
                    if (this.isEmptyData(this.datadef.visible[items.name])){
                      items.visible = false;
                    }
                  }
                }
              }
            }
            //console.log("jjjj2222",this.datadef)
            //模块展示，目前仅分享展示，不编辑
            if (list.length != 0){
              for (let k in this.datalist.groups){
                let its = this.datalist.groups[k];
                if (its.type == 'list' && its.tempName != undefined && its.tempName != ''){
                  for (let l in list){
                    if (its.tempName == list[l].name){
                      for (let n in list[l].data){
                        its.fields.push(list[l].data[n]);
                      }
                    }
                  }
                }
              }
            }
            //console.log('form444', this.datalist,this.datainfo);
          } else {
            //alert(JSON.stringify(res.data));
            if (res.data.errorCode == "500"){
              Toast.fail(res.data.errorMsg);
              this.dis = 3;
                /*if (res.data.errorMsg == "数据不存在！"){}else {

              }*/
            }else {
              Toast.fail(res.data.errorMsg);
              console.log("res1111",res)
            }
          }
        })
      }

    },

  }
}
</script>

<style scoped>
</style>